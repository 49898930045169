import {dimensions, measures} from './qlik/klant/klant';
import {dimensions as jks_dimensions, measures as jks_measures} from './qlik/klant/jks';

export default [
    {
      value: 'dimensions',
      label: 'Dimensies',
      class: 'bg-primary',
      children: [
        {
          label: 'Klant',
          value: 'klant',
          children: [
            {
              qlik: dimensions.debtor.name,
              label: 'Naam',
              value: 'vD_Order.Debtor.Name'
            },
            {
              qlik: dimensions.debtor.number,
              label: 'Nummer',
              value: 'vD_Order.Debtor.Number'
            },
            {
              qlik: jks_dimensions.debtor.group,  
              label: 'Groep',
              value: 'vD_Order.Debtor.Group'
            },
            {
              qlik: jks_dimensions.debtor.financenumber,  
              label: 'Financieelnummer',
              value: 'vD_Order.Debtor.FinancialNumber'
            },
          ]
        },
        {
          label: 'Order',
          value: 'order',
          children: [
            {
              qlik: dimensions.order.ordernumberdisplay,
              value: 'vD_Order.OrderNumberDisplay',
              label: 'Order Nummer'
            },
            {
              qlik: jks_dimensions.order.dossier,
              value: 'vD_^JKS.Order.Dossier',
              label: 'Dossier'
            },
          ]
        },
        {
          label: 'Overig',
          value: 'overig',
          children: [
            {
              qlik: jks_dimensions.order.servicedescription,
              value: 'vD_Order.ServiceDescription',
              label: 'Service'
            },
            {
              qlik: dimensions.order.kind,
              value: 'vD_Order.Kind',
              label: 'Ordersoort'
            },
            {
              qlik: jks_dimensions.order.accountmanager,
              value: 'vD_^Order.AccountManager',
              label: 'Accountmanager'
            },
            {
              qlik: jks_dimensions.order.relatiebeheerder,
              value: 'vD_^Order.RelatieBeheerder',
              label: 'Relatiebeheerder'
            },
            {
              qlik: jks_dimensions.order.team,
              value: 'vD_^Order.Team',
              label: 'Team'
            },
            {
              qlik: jks_dimensions.order.afzender,
              value: 'vD_Order.Afzender',
              label: 'Afzender'
            },
            {
              qlik: jks_dimensions.order.division,
              value: 'vD_Order.Division',
              label: 'Afdeling'
            },
            {
              qlik: jks_dimensions.order.zendinggroottegroep,
              value: 'vD_Order.Zendinggrootte.Group',
              label: 'Groep m3'
            },
          ]
        },
        {
          label: 'Laad Locatie',
          value: 'laadlocatie',
          children: [
            {
              qlik: dimensions.loadinglocation.id,
              value: 'vD_Order.LoadingLocation.ID',
              label: 'ID'
            },
            {
              qlik: dimensions.loadinglocation.name,
              value: 'vD_Order.LoadingLocation.Name',
              label: 'Naam'
            },
            {
              qlik: dimensions.loadinglocation.adress,
              value: 'vD_Order.LoadingLocation.Adress',
              label: 'Adres'
            },
            {
              qlik: dimensions.loadinglocation.zipcode,
              value: 'vD_Order.LoadingLocation.ZIPcode',
              label: 'Postcode'
            },
            {
              qlik: dimensions.loadinglocation.city,
              value: 'vD_Order.LoadingLocation.City',
              label: 'Plaats'
            },
            {
              qlik: dimensions.loadinglocation.region,
              value: 'vD_Order.LoadingLocation.Region',
              label: 'Regio'
            },
            {
              qlik: dimensions.loadinglocation.financialregion,
              value: 'vD_Order.LoadingLocation.FinancialRegion',
              label: 'F-Regio - Code'
            },
            {
              qlik: dimensions.loadinglocation.financialregiondescription,
              value: 'vD_Order.LoadingLocation.FinancialRegionDescription',
              label: 'F-Regio - Omschrijving'
            },
            {
              qlik: dimensions.loadinglocation.operationalregion,
              value: 'vD_Order.LoadingLocation.OperationalRegion',
              label: 'O-Regio - Code'
            },
            {
              qlik: dimensions.loadinglocation.operationalregiondescription,
              value: 'vD_Order.LoadingLocation.OperationalRegionDescription',
              label: 'O-Regio - Omschrijving'
            },
            {
              qlik: dimensions.loadinglocation.country,
              value: 'vD_Order.LoadingLocation.Country',
              label: 'Land'
            },
          ]
        },
        {
          label: 'Los Locatie',
          value: 'loslocatie',
          children: [
            {
              qlik: dimensions.unloadinglocation.id,
              value: 'vD_Order.UnloadingLocation.ID',
              label: 'ID'
            },
            {
              qlik: dimensions.unloadinglocation.name,
              value: 'vD_Order.UnloadingLocation.Name',
              label: 'Naam'
            },
            {
              qlik: dimensions.unloadinglocation.adress,
              value: 'vD_Order.UnloadingLocation.Adress',
              label: 'Adres'
            },
            {
              qlik: dimensions.unloadinglocation.zipcode,
              value: 'vD_Order.UnloadingLocation.ZIPcode',
              label: 'Postcode'
            },
            {
              qlik: dimensions.unloadinglocation.city,
              value: 'vD_Order.UnloadingLocation.City',
              label: 'Plaats'
            },
            {
              qlik: dimensions.unloadinglocation.region,
              value: 'vD_Order.UnloadingLocation.Region',
              label: 'Regio'
            },
            {
              qlik: dimensions.unloadinglocation.financialregion,
              value: 'vD_Order.UnloadingLocation.FinancialRegion',
              label: 'F-Regio - Code'
            },
            {
              qlik: dimensions.unloadinglocation.financialregiondescription,
              value: 'vD_Order.UnloadingLocation.FinancialRegionDescription',
              label: 'F-Regio - Omschrijving'
            },
            {
              qlik: dimensions.unloadinglocation.operationalregiondescription,
              value: 'vD_Order.UnloadingLocation.OperationalRegion',
              label: 'O-Regio - Code'
            },
            {
              qlik: dimensions.unloadinglocation.operationalregiondescription,
              value: 'vD_Order.UnloadingLocation.OperationalRegionDescription',
              label: 'O-Regio - Omschrijving'
            },
            {
              qlik: dimensions.unloadinglocation.country,
              value: 'vD_Order.UnloadingLocation.Country',
              label: 'Land'
            },
          ]
        },
        {
          label: 'Periode',
          value: 'periode',
          children: [
            {
              qlik: dimensions.period.year,
              value: 'vD_Year',
              label: 'Jaar'
            },
            {
              qlik: dimensions.period.quarter,
              value: 'vD_Quarter',
              label: 'Kwartaal'
            },
            {
              qlik: dimensions.period.month,
              value: 'vD_Month',
              label: 'Maand'
            },
            {
              qlik: dimensions.period.week,
              value: 'vD_Week',
              label: 'Week'
            },
            {
              qlik: dimensions.period.date,
              value: 'vD_Date',
              label: 'Datum'
            },
            {
              qlik: dimensions.period.date,
              value: 'vD_WeekDay',
              label: 'Dag'
            },
          ]
        }
      ]
    },
    {
      label: 'Meetwaarden',
      value: 'measures',
      class: 'bg-danger',
      children: [
        {
          value: 'omzet',
          label: 'Omzet',
          children: [
            {
              qlik: measures.omzet.totaal,
              value: 'vE_OrderOmzet',
              label: 'Totaal',
            },
            {
              qlik: jks_measures.omzet.notransport,
              value: 'vE_OrderOmzetNoTransport',
              label: 'Geen transport',
              help: 'Omzet die niet gerelateerd is aan transport activiteiten zoals inhuisomzet (wordt niet meegenomen in kengetallen)',
            },
          ]
        },
        {
          value: 'kosten',
          label: 'Kosten',
          children: [
            {
              qlik: measures.kosten.totaal,
              value: 'vE_OrderKosten',
              label: 'Totaal',
              help: 'Totaal van de directe ritkosten. De kosten zijn toebedeeld vanuit de rit naar een order.'
            },
            {
              qlik: measures.kosten.charter,
              value: 'vE_OrderKostenCharter',
              label: 'Derden',
              help: 'Charter kosten van uitbestede ritten, dit zijn de geboekte kosten in Plan & Go.'
            },
            {
              qlik: measures.kosten.toll,
              value: 'vE_OrderKostenTol',
              label: 'Tol',
              help: 'Tolkosten vanuit de tolcalculatie in Plan & Go op basis van de afstandentabel'
            },
            {
              qlik: measures.kosten.codriver,
              value: 'vE_OrderKostenCoDriver',
              label: 'Bijrijder',
              help: 'Bijrijder kosten vanuit de geboekte kosten in Plan & Go of gecalculeerd op basis van toegekende uren x uurtarief'
            },
            {
              qlik: measures.kosten.warehouse,
              value: 'vE_OrderKostenWarehouse',
              label: 'Crossdock',
              help: 'Voorcalculatorische kosten berekend op basis van de laadmeters x tarief'
            },
          {
            qlik: measures.kosten.rail,
            value: 'vE_TripKostenRail',
            label: 'Ferry',
            help: 'Geboekte trein kosten vanuit Plan & Go'
          },
            {
              qlik: measures.kosten.other,
              value: 'vE_OrderKostenOther',
              label: 'Other',
              help: 'Alle kosten die niet in één van bovenstaande categoriën ingedeeld zijn vallen hieronder'
            },
          ]
        },
        {
          value: 'aantal',
          label: 'Aantal',
          children: [
            {
              qlik: measures.aantal.orders,
              value: 'vE_OrderAantalOrders',
              label: 'Order',
              help: 'Het aantal orders, waarbij een order een unieke transportopdracht is van A naar B'

            },
            {
              qlik: measures.aantal.ritten,
              value: 'vE_OrderAantalRitten',
              label: 'Ritten',
              help: 'Het aantal ritten behorend bij de geselecteerde ritten. Hier zien we altijd het totaal. '
            },
          ]
        },
        {
          value: 'kpi',
          label: 'KPI',
          children: [
            {
              qlik: measures.kpi.goedkeur,
              value: 'vE_KPI_OrderGoedkeur',
              label: 'OK',
              help: 'Het percentage orders dat goedgekeurd is. Wanneer er iets misgaat met een order wordt deze niet meegenomen in de kerngetallen.'
            },
  
            {
              qlik: measures.kpi.normmarginpercent,
              value: 'vE_KPI_OrderNormMarginPercent',
              label: 'Norm Marge %',
              help: 'De Norm Marge combineert de charter marge en de omzet/uur om deze samen te toetsen tegen de gestelde normen. Voor het eigen werk wordt gekeken hoeveel de omzet / uur afwijkt van de norm voor de omzet/uur. Dit wordt gecombineerd met de afwijking van de chartermarge ten opzichte van de norm voor de chartermarge. Dit wordt enkel uitgerekend voor goedgekeurde orders.'
            },
            {
              qlik: measures.kpi.normmarginomzet,
              value: 'vE_KPI_OrderNormMarginOverOmzet',
              label: 'Norm Marge',
              help: 'De Norm Marge interpoleert het berekende norm percentage over de gehele omzet zodat je ziet wat je marge is euro\'s.'
            },
  
  
          ]
        },
        {
          value: 'eigenwagen',
          label: 'Eigen Wagen',
          children: [
            {
              qlik: measures.own.omzetperuur,
              value: 'vE_KPI_OrderOmzetPerUur',
              label: 'Omzet / Uur',
              help: 'De omzet / uur is de omzet van het eigen werk minus de directe-kosten (Tol, X-dock) van het eigen werk afgezet tegen het aantal ingezette uren. Het gaat hier altijd enkel om goedgekeurde orders. '
            },
            {
              qlik: measures.own.tripomzetperuur,
              value: 'vE_KPI_Order_TripOmzetPerUur',
              label: 'Omzet / Uur - rit',
              help: 'De omzet / uur van de gehele rit(ten) waar deze order(s) ingezeten heeft/hebben. Het gaat hier altijd enkel om goedgekeurde ritten. '
            },
            {
              qlik: measures.own.bezetting,
              value: 'vE_KPI_OrderBezetting',
              label: 'Bezetting',
              help: 'De bezetting toont het percentage van inzet van het voertuig op basis van de uren uit de boordcomputer ten opzichte van de verwachte inzet (12 uur per werkdag). '
            },
            {
              qlik: measures.own.normomzetperuur,
              value: 'vE_OrderNormTurnoverHour',
              label: 'Norm Omzet / Uur',
              help: 'De geconfigureerde norm op orderniveau voor het eigen werk. De omzet per uur wordt getoetst aan deze norm. '
            },
            // {
            //   value: 'NarekenenEigenWagen',
            //   label: 'Narekenen KPI',
            //   children: [
            //     {
            //       value: 'NarekenenEigenWagen.Omzet',
            //       label: 'Omzet',
            //       children: [
            //         {
            //           qlik: measures.own.normomzetperuur,
            //           value: 'vE_OrderNormTurnoverHour',
            //           label: 'Totaal'
            //         },
            //         {
            //           qlik: measures.own.normomzetperuur,
            //           value: 'vE_OrderNormTurnoverHour',
            //           label: 'Eigen Werk'
            //         },
            //         {
            //           qlik: measures.own.normomzetperuur,
            //           value: 'vE_OrderNormTurnoverHour',
            //           label: 'Goedgekeurd'
            //         },
            //       ]
            //     },
            //     {
            //       value: 'NarekenenEigenWagen.Kosten',
            //       label: 'Kosten',
            //       children: [
            //         {
            //           qlik: measures.own.normomzetperuur,
            //           value: 'vE_OrderNormTurnoverHour',
            //           label: 'Totaal'
            //         },
            //         {
            //           qlik: measures.own.normomzetperuur,
            //           value: 'vE_OrderNormTurnoverHour',
            //           label: 'Eigen Werk'
            //         },
            //         {
            //           qlik: measures.own.normomzetperuur,
            //           value: 'vE_OrderNormTurnoverHour',
            //           label: 'Goedgekeurd'
            //         },
            //       ]
            //     },
            //     {
            //       value: 'NarekenenEigenWagen.Uren',
            //       label: 'Uren',
            //       children: [
            //         {
            //           qlik: measures.own.normomzetperuur,
            //           value: 'vE_OrderNormTurnoverHour',
            //           label: 'Totaal'
            //         },
            //         {
            //           qlik: measures.own.normomzetperuur,
            //           value: 'vE_OrderNormTurnoverHour',
            //           label: 'Eigen Werk'
            //         },
            //         {
            //           qlik: measures.own.normomzetperuur,
            //           value: 'vE_OrderNormTurnoverHour',
            //           label: 'Goedgekeurd'
            //         },
            //       ]
            //     },
            //     {
            //       qlik: measures.own.normomzetperuur,
            //       value: 'vE_OrderNormTurnoverHour',
            //       label: 'Berekening'
            //     },
            //   ]
            // }
          ]
        },
        {
          value: 'uitbesteed',
          label: 'Uitbesteed',
          children: [
            {
              qlik: measures.outsourced.percentage,
              value: 'vE_KPI_OrderTripOutsourced',
              label: '% Uitbesteed',
              help: 'Het percentage van de order dat is uitbesteed. Indien een order in het voorhaaltraject door een eigen wagen uitgevoerd wordt en het wegbrengen wordt uitbesteed is deze zending voor 50% uitbesteed.'
            },
            {
              qlik: measures.outsourced.marge,
              value: 'vE_KPI_OrderCharterMarge',
              label: 'Derden Marge',
              help: 'De marge op het uitbestede werk. Dit is de omzet minus de directe ritkosten. Hierbij wordt enkel de omzet en de kosten meegenomen indien de order goedgekeurd is. '
            },
            {
              qlik: measures.outsourced.norm,
              value: 'vE_OrderNormCharter',
              label: 'Norm',
              help: 'De geconfigureerde norm op orderniveau voor het uitbestede werk. De derden marge wordt getoetst aan deze norm. '
            },
          ]
        },
        {
          value: 'potency',
          label: 'Potentie',
          children: [
            {
              qlik: measures.potentie.totaal,
              value: 'vE_OrderPotency',
              label: 'Totaal',
              help: 'Potentie is het bedrag aan omzet dat gewonnen kan worden door uitvoer van de orders (operationeel) en hogere tarieven (commercieel) op basis van de zelf gekozen normen. '
            },
            {
              qlik: measures.potentie.commercieel,
              value: 'vE_OrderPotencyCommercial',
              label: 'Commercieel',
              help: 'Commerciele potentie is het bedrag aan omzet dat gewonnen kan worden door hogere tarieven (commercieel) op basis van de zelf gekozen normen. '
            },
            {
              qlik: measures.potentie.operationeel,
              value: 'vE_OrderPotencyOperational',
              label: 'Operationeel',
              help: 'Operationele potentie is het bedrag aan omzet dat gewonnen kan worden door betere uitvoer van de orders op basis van de zelf gekozen normen. '

            },
          ]
        },
        {
          value: 'km',
          label: 'KM',
          children: [
            {
              qlik: measures.km.km,
              value: 'vE_OrderKM',
              label: 'Totaal',
              help: 'Geeft de totale kilometers op orderniveau weer die toebedeeld zijn vanuit de rit.'
            },
            {
              qlik: measures.km.omzetperkm,
              value: 'vE_KPI_OrderOmzetPerKM',
              label: 'Omzet / KM',
              help: 'Geeft de omzet per kilometer weer. Hierbij wordt enkel de goedgekeurde omzet afgezet tegen de goedgekeurde kilometers'
            },
            {
              qlik: measures.km.normomzetperkm,
              value: 'vE_OrderNormTurnoverKM',
              label: 'Norm Omzet / KM',
              help: 'De ingestelde norm op orderniveau voor de Omzet / KM.'
            }
          ]
        },
        {
          value: 'uren',
          label: 'Uren',
          children: [
            {
              qlik: measures.uren.totaal,
              value: 'vE_OrderUren',
              label: 'Totaal (Σ)',
              help: 'Het totaal aantal uren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. '
            },
            {
              qlik: measures.uren.loading,
              value: 'vE_OrderUrenLoading',
              label: 'Laden (Σ)',
              help: 'Het totaal aantal laaduren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer.'
            },
            {
              qlik: measures.uren.unloading,
              value: 'vE_OrderUrenUnloading',
              label: 'Lossen (Σ)',
              help: 'Het totaal aantal losuren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer.'
            },
            {
              qlik: measures.uren.driving,
              value: 'vE_OrderUrenDriving',
              label: 'Rijden (Σ)',
              help: 'Het totaal aantal rijuren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer.'
            },
            {
              qlik: measures.uren.other,
              value: 'vE_OrderUrenOther',
              label: 'Overig (Σ)',
              help: 'Het totaal aantal overige uren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer.'
            },
            {
              qlik: measures.uren.avg_totaal,
              value: 'vE_OrderUrenAvg',
              label: 'Totaal (avg)',
              help: 'Het gemiddeld aantal uren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer.'
            },
            {
              qlik: measures.uren.avg_loading,
              value: 'vE_OrderUrenAvgLoading',
              label: 'Laden (avg)',
              help: 'Het gemiddeld aantal laaduren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer.'
            },
            {
              qlik: measures.uren.avg_unloading,
              value: 'vE_OrderUrenAvgUnloading',
              label: 'Lossen (avg)',
              help: 'Het gemiddeld aantal losuren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer.'
            },
            {
              qlik: measures.uren.avg_driving,
              value: 'vE_OrderUrenAvgDriving',
              label: 'Rijden (avg)',
              help: 'Het gemiddeld aantal rijuren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer.'
            },
            {
              qlik: measures.uren.avg_other,
              value: 'vE_OrderUrenAvgOther',
              label: 'Overig (avg)', 
              help: 'Het gemiddeld aantal overige uren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer.'    
            }
          ]
        },
        {
          value: 'vergelijkperiode',
          label: 'Voorgaand jaar',
          children: [
            {
              qlik: measures.previousyear.omzet, 
              value: 'vE_OrderOmzetVorigJaar',
              label: 'Omzet',
            },
            {
              qlik: measures.previousyear.orders,
              value: 'vE_OrderAantalOrdersVorigJaar',
              label: 'Order',
            },
            {
              qlik: measures.previousyear.ritten,
              value: 'vE_OrderAantalRittenVorigJaar',
              label: 'Ritten',
            },
            {
              qlik: measures.previousyear.goedkeur,
              value: 'vE_KPI_OrderGoedkeurVorigJaar',
              label: 'OK'
            },
            {
              qlik: measures.previousyear.normmarge,
              value: 'vE_KPI_OrderNormMarginPercentVorigJaar',
              label: 'Norm Marge %'
            },
            {
              qlik: measures.previousyear.normmargeomzet,
              value: 'vE_KPI_OrderNormMarginOverOmzetVorigJaar',
              label: 'Norm Marge'
            },
            {
              qlik: measures.previousyear.omzetperuur,
              value: 'vE_KPI_OrderOmzetPerUurVorigJaar',
              label: 'Omzet / Uur'
            },
            {
              qlik: measures.previousyear.uitbesteed,
              value: 'vE_KPI_OrderTripOutsourcedVorigJaar',
              label: '% Uitbesteed'
            },
            {
              qlik: measures.previousyear.chargermarge,
              value: 'vE_KPI_OrderCharterMargeVorigJaar',
              label: 'Derden Marge'
            },
  
          ],
        },
        {
          value: 'persize',
          label: 'Per Eenheid',
          children: [
            {
              value: 'm3',
              label: 'M3',
              children: [
                {
                  qlik: measures.persize.shipmentsize,
                  value: 'vE_OrderShipmentSize',
                  label: 'M3',
                  help: 'Het totaal aantal m3 vanuit Plan & Go'
                },
                        {
                  qlik: measures.persize.shipmentsizeavg,
                  value: 'vE_OrderShipmentSizeAvg',
                  label: 'Gem. M3',
                  help: 'Het gemiddeld aantal m3 vanuit Plan & Go'
                },
                {
                  qlik: measures.persize.omzet_shipmentsize,
                  value: 'vE_KPI_OrderOmzetPerZendingGroottePervE_OrderShipmentSize',
                  label: 'Omzet Per M3',
                },
                {
                  qlik: measures.persize.loading_shipmentsize,
                  value: 'vE_KPI_OrderLaadMinutenPerZendingGrootte',
                  label: 'Laad minuten per M3'
                },
                {
                  qlik: measures.persize.unloading_shipmentsize,
                  value: 'vE_KPI_OrderLosMinutenPerZendingGrootte',
                  label: 'Los minuten per M3'
                },
                {
                  qlik: measures.persize.time_shipmentsize,
                  value: 'vE_KPI_OrderMinutenPerZendingGrootte',
                  label: 'Tijd per M3'
                },
              ]
            },
            {
              value: 'kg',
              label: 'KG',
              children: [
                {
                  qlik: measures.persize.kg,
                  value: 'vE_OrderKG',
                  label: 'Totaal',
                  help: 'Het totaal aantal kilo\'s vanuit Plan&Go'
                },
                {
                  qlik: measures.persize.avg_kg,
                  value: 'vE_OrderKGAvg',
                  label: 'Gemiddelde',
                  help: 'Het gemiddelde aantal kilo\'s vanuit Plan&Go'

                }
              ]
            },
            {
              value: 'colli',
              label: 'Colli',
              children: [
                {
                  qlik:measures.persize.colli,
                  value: 'vE_OrderColli',
                  label: 'Totaal',
                  help: 'Het totaal aantal colli vanuit Plan&Go'

                },
                {
                  qlik:measures.persize.avg_colli,
                  value: 'vE_OrderColliAvg',
                  label: 'Gemiddelde',
                  help: 'Het gemiddelde aantal colli vanuit Plan&Go'

                }
              ]
            },
          ]
        },
      ]
    }
  ];
  